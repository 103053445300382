import { Box, Card, CardContent, Slide, Stack, Typography } from "@mui/material";
import { useEffect, useMemo } from "react";
import { Download } from "@mui/icons-material";
import {
  ButtonRow,
  EStrikeOAuthFeature,
  ReportFiltersToolbar,
  ReportItem,
  ReportTable,
  REPORT_ENDPOINTS,
  useAccount,
  useCsvBlob,
  useReportCsv,
  useReportJson,
  useReportFiltersForm,
  useStrikeConfiguration,
  useDefaultError,
  useCurrencyConverter,
  ActionButton,
  ReportJsonTable,
} from "@synota-io/synota-shared-ui";

const ReportsDownloader = () => {
  const { hasLightningNode, isConsumer, isSupplier, userGroupId, self, paymentMethod, isReady } =
    useAccount();

  const { isBluePenguin: isCurrencyConverterBluePenguin } = useCurrencyConverter();

  const isBluePenguin = isCurrencyConverterBluePenguin || paymentMethod?.isBluePenguin;

  const { hasAllFeatures } = useStrikeConfiguration({
    features: [EStrikeOAuthFeature.AutomaticPayout],
  });

  const reports: (ReportItem & { display: boolean })[] = useMemo(
    () =>
      [
        {
          ...REPORT_ENDPOINTS.CONTRACT_SUMMARY,
          display: true,
        },
        {
          ...REPORT_ENDPOINTS.DEPOSIT_DETAILS,
          display: Boolean(isConsumer && hasLightningNode && !isBluePenguin),
        },

        {
          ...REPORT_ENDPOINTS.INVOICE_DETAILS,
          display: true,
        },

        {
          ...REPORT_ENDPOINTS.PAYMENT_DETAILS,
          display: true,
        },
        {
          ...REPORT_ENDPOINTS.WITHDRAWAL_DETAILS,
          display: Boolean(isConsumer && !paymentMethod?.isNone && !isBluePenguin),
        },
        {
          ...REPORT_ENDPOINTS.REVENUE_AND_EXPENSES,
          display: Boolean(userGroupId && isSupplier),
        },
        {
          ...REPORT_ENDPOINTS.DISBURSEMENT_DETAILS,
          display: Boolean(isSupplier && hasAllFeatures && !isBluePenguin),
        },
        {
          ...REPORT_ENDPOINTS.STRIKE_RECONCILIATION,
          display: Boolean(isSupplier && hasAllFeatures && !isBluePenguin),
        },
        {
          ...REPORT_ENDPOINTS.FEE_DETAILS,
          display: Boolean(
            (isConsumer || hasAllFeatures) && !paymentMethod?.isNone && !isBluePenguin,
          ),
        },
        {
          ...REPORT_ENDPOINTS.FEE_SUMMARY,
          display: Boolean(
            (isConsumer || hasAllFeatures) && !paymentMethod?.isNone && !isBluePenguin,
          ),
        },
        {
          ...REPORT_ENDPOINTS.NODE_RECONCILIATION,
          display: Boolean(isConsumer && hasLightningNode),
          hideHeader: true,
        },
        {
          ...REPORT_ENDPOINTS.REVENUE_AND_EXPENSES,
          display: Boolean(userGroupId && isConsumer),
        },
      ].filter((dataTable) => dataTable.display !== false),
    [
      hasAllFeatures,
      hasLightningNode,
      isBluePenguin,
      isConsumer,
      isSupplier,
      paymentMethod?.isNone,
      userGroupId,
    ],
  );

  const { control, reset, watch } = useReportFiltersForm({
    defaultReport: reports[0],
    reports,
  });

  const values = watch();

  useEffect(() => {
    if (!reports.find((r) => r.path === values.report?.path) && values.report.path !== "") {
      reset();
    }
  }, [reports, reset, self, values]);

  const { blob, error, isFetching, download, params } = useReportCsv({
    values,
    options: {
      enabled: isReady,
    },
  });

  const jsonReport = useReportJson({
    params: { ...params, path: values.report.jsonPath || "" },
    options: { enabled: !!values.report.jsonPath },
  });

  const {
    data,
    columns,
    isLoading: isLoadingBlob,
    error: csvError,
  } = useCsvBlob({
    blob,
    hasHeaderRow: !values.report?.hideHeader,
  });

  useDefaultError(error);
  useDefaultError(csvError);

  return (
    <Stack spacing={6} flexGrow={1}>
      <ButtonRow>
        <Typography variant="h5">Reports</Typography>
        <Box flexGrow={1} />
        <ActionButton
          color="primary"
          title="Download Report"
          size="small"
          onClick={download}
          isLoading={isFetching}
          disabled={!data?.length}
        >
          <Download fontSize="small" />
        </ActionButton>
      </ButtonRow>
      <ReportFiltersToolbar items={reports} control={control} />
      <Box overflow="visible">
        <Slide in direction="up">
          <Card>
            <CardContent>
              {values.report.jsonPath ? (
                <ReportJsonTable
                  {...jsonReport.data}
                  values={values}
                  isLoading={jsonReport.isFetching}
                />
              ) : (
                <ReportTable
                  data={data}
                  columns={columns}
                  values={values}
                  isLoading={isFetching || isLoadingBlob}
                />
              )}
            </CardContent>
          </Card>
        </Slide>
      </Box>
    </Stack>
  );
};

export { ReportsDownloader };
